._3DswDMo3rPhwmGGm-RnGoI {
  position: relative;
}

._3DswDMo3rPhwmGGm-RnGoI input {
  font-size: 14px;
  height: 40px;
  padding-left: 48px;
  border: 1px solid #b0b0b0;
  border-radius: 0.25rem;
  width: 100% !important;
}

._3DswDMo3rPhwmGGm-RnGoI td[aria-selected="true"] div {
  background-color: #2ecc71;
  color: white;
}

._3DswDMo3rPhwmGGm-RnGoI span {
  vertical-align: middle;
}

._1S2oVfy_sqWLNSz8I7kVKD {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  position: absolute;
  left: 0;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #b0b0b0;
}

._2-I6T1F9kpUp2j9G31LMYN {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .075);
          box-shadow: 0 2px 5px rgba(0, 0, 0, .075);
  border: 1px solid #d0d0d0;
  margin-top: 4px;
  margin-top: 0.25rem;
  margin-left: -2px;
  margin-left: -0.125rem;
  border-radius: 0.25rem;
  /* NASTY HACK TO DIG INTO ATLASKIT COMPONENT */
  /* NASTY HACK TO DIG INTO ATLASKIT COMPONENT */
}

._2-I6T1F9kpUp2j9G31LMYN > div {
  border-radius: inherit;
}

._2-I6T1F9kpUp2j9G31LMYN > div > div {
  border-radius: inherit;
}

