._3c5eahGieigDTmRHO-d7l3,
._2NFgh0Z6tkGorUToiId47k {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 28px;
  line-height: 1.75rem;
  margin-top: 4px;
  margin-top: 0.25rem;
}

._3c5eahGieigDTmRHO-d7l3 [class^="Content__ChildWrapper"] > [class^="Content"],
._2NFgh0Z6tkGorUToiId47k [class^="Content__ChildWrapper"] > [class^="Content"] {
  border-color: #bbb;
  border-width: 1px;
  background-color: white;
}

@media screen and (max-width: 768px) {
  ._3c5eahGieigDTmRHO-d7l3,
  ._2NFgh0Z6tkGorUToiId47k {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

._3c5eahGieigDTmRHO-d7l3 .status-dropdown__option > div,
._2NFgh0Z6tkGorUToiId47k .status-dropdown__option > div,
._3c5eahGieigDTmRHO-d7l3 .status-dropdown__single-value > div,
._2NFgh0Z6tkGorUToiId47k .status-dropdown__single-value > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

._3c5eahGieigDTmRHO-d7l3 .status-dropdown__option,
._2NFgh0Z6tkGorUToiId47k .status-dropdown__option {
  position: relative;
}

._3c5eahGieigDTmRHO-d7l3 .status-dropdown__option::before,
._2NFgh0Z6tkGorUToiId47k .status-dropdown__option::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 10px;
  top: 13px;
  left: 17px;
  z-index: 1;
  background-color: white;
}

._38pbMcqJ77EGfPqseO7Gxg {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

._38pbMcqJ77EGfPqseO7Gxg ._3cRRSE6UNDPUEXZCBwF56D {
  width: 100%;
}

._38pbMcqJ77EGfPqseO7Gxg > label {
  width: 100%;
}

._3sZIxoRKMF5wZfiPS6UBcZ {
  -ms-flex-preferred-size: content;
      flex-basis: content;
  white-space: nowrap;
  margin-right: 16px;
  margin-right: 1rem;
  margin-bottom: 0 !important;
  line-height: 40px;
  line-height: 2.5rem;
}

._3sZIxoRKMF5wZfiPS6UBcZ input[type='checkbox'] {
  margin-right: 12px;
  margin-right: 0.75rem;
  margin-top: 0;
}

._3QmuBtWt51qcyPXby622wM {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 40px;
  min-width: 97px;
  margin-left: -5px;
  padding-right: 10px;
}

._3QmuBtWt51qcyPXby622wM input[type='checkbox'] {
  visibility: hidden;
  position: absolute;
}

._3cRRSE6UNDPUEXZCBwF56D {
  width: 240px;
  height: 40px;
  padding: 0;
}

@media screen and (max-width: 768px) {
  ._3cRRSE6UNDPUEXZCBwF56D {
    width: 100%;
  }
}

._3dZEMCFENcC-7J99E-_ryB {
  display: none;
}

._2NFgh0Z6tkGorUToiId47k {
  margin-top: 0;
}

._2NFgh0Z6tkGorUToiId47k label > label {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

._2cgnn8jOgeTLsVfOQFh5Rs,
.EXjpOz67RQ324-IAAubN5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.EXjpOz67RQ324-IAAubN5 i {
  color: #A5ADBA !important;
}

